import React from "react"

// Components
import Seo from "components/seo"

// Page Sections
import WebinarVideo from "./../screens/webinar-replay/webinar-video"
import BodyLanding from "./../screens/webinar-replay/2022-02-24-muzny/body-landing"
import SeriousTargets from "./../screens/webinar-replay/serious-targets"

const MuznyView = () => {
	return (
		<React.Fragment>
			<WebinarVideo
				videoTitle="Don’t Get Tricked by Trichomonas: A Neglected Sexually Transmitted Infection"
				videoDisplayTitle="Don’t Get Tricked by Trichomonas: A Neglected Sexually Transmitted Infection"
				vimeoLink="https://player.vimeo.com/video/684360217?h=def3346398"
				placeholderImage="2022-02-24-Muzny-Visby-placeholder.jpg"
				localStorageID="2022-02-24-muzny-webinar-replay"
				pageSlug="/webinar-replay/2022-02-24-muzny/"
			/>
			<BodyLanding pageName="download" localStorageID="2022-02-24-muzny-webinar-replay" />
			<SeriousTargets />
		</React.Fragment>
	)
}

export default MuznyView

export const Head = () => (
  <Seo
		title="Webinar Replay: Don't Get Tricked by Trichomonas: A Neglected STI"
		description="Presenter Dr. Christina Muzny talks about not getting tricked by Trichomonas: A Neglected Sexually Transmitted Infection."
		image="/meta/webinar-muzny-2022-02-24.jpg"
  />
)
